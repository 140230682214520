// Theme Colors/Branding
$brand-primary: #5cb176;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #D090E6;
$dark: #255551;
$light: #F2F2EF;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: #D090E6;
$accent-2: #D090E6;
$body-font: #222;
$error: #d81e00;