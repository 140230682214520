.rpb-cards {
  .card {
    border:0;
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
    .card-header {
      background:unset;
      border-bottom:unset;
    }
  }
  // Vertical Align Class
  .vertically-aligned-card {
    display:flex;
    flex-direction:column;
    justify-content:center;
  }
  // Image Classes
  .bg-img-card {
    display:none;
  }
  // Default Slider Styles
  &.bootstrap-cards-slider {
    .splide__slider {
      .rpb-slider-arrows {
        .splide__arrow--prev {
          left:0;
        }
        .splide__arrow--next {
          right:0;
        }
      }
      .splide__track {
        margin-left:3rem;
        margin-right:3rem;
      }
      .splide__pagination {
        bottom:-2.5rem;
      }
    }
  }
}


.option-cards {
  .card {
    transition: $transition-med;
    outline-color: $brand-primary;
    background-color: $accent;

    .card-header {
      font-weight: $heavy-weight;
      transition: $transition-fast;
      text-transform: uppercase;
      font-size: 250%;
      font-family: $heading-font;
    }

    .card-image-top, .rpb-flex-card-image {
      transition: $transition-fast;
    }

    &.picked {
      outline: 8px solid $brand-primary;
      background-color: $light;
      transform: scale(1.2);
      position: relative;
      z-index: 2;
      @extend .shadow-lg;

      .card-header {
        letter-spacing: 2px;
        color: $brand-primary;
        animation: swell 0.5s 0s forwards;
      }

      .rpb-flex-card-image {
        transform: translateY(-50px);
      }

      .card-img-top {
        animation: bouncing 0.5s .3s;
      }
    }
  }
}

@keyframes bouncing {
  from, to { transform: scale(1, 1); }
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  75% { transform: scale(0.95, 1.05); }
}

@keyframes swell {
  0% { transform: scale(1) translateY(0px); }
  25% { transform: scale(1.3) translateY(-70px); }
  50% { transform: scale(1.2) translateY(-60px); }
  75% { transform: scale(1.1) translateY(-40px); }
  100% { transform: scale(1) translateY(-40px); }
}