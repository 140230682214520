/*
** font sizes calculated in perfect fourths using https://type-scale.com/
*/
// set type
$menu-font: 'Red Hat Display', Helvetica, Arial, sans-serif;
$heading-font: 'Fraunces', serif;
$subheading-font: 'Red Hat Display', Helvetica, Arial, sans-serif;
$paragraph-font: 'Red Hat Display', Helvetica, Arial, sans-serif; // set weights
$light-weight: 300;
$normal-weight: 400;
$medium-weight: 600;
$heavy-weight: 700;
$ultra-weight: 900;

body {
  font-size: 16px;
  line-height: 1.5rem;
  color: $body-font;
  font-family: $paragraph-font;
  @include media-breakpoint-up(md) {
    font-size: 1.125rem; // 18px
  }
}

a {
  color: $brand-primary;
  font-weight: $heavy-weight;
  text-decoration: none;
  transition-duration: 350ms;
  -webkit-transition-duration: 350ms;
  -moz-transition-duration: 350ms;

  &:hover {
    text-decoration: none;
    color: $brand-secondary;
  }
}

.bold,
strong {
  font-weight: 700;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  margin-top: 0;
  margin-bottom: 1rem;
  letter-spacing: 1px;
}

.h1,
h1 {
  font-weight: $heavy-weight;
  font-size: 2.369rem;
  @include media-breakpoint-up(lg) {
    font-size: 3.157rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 4.357rem;
  }
}

.h2,
h2 {
  font-weight: $heavy-weight;
  font-size: 1.777rem;
  @include media-breakpoint-up(lg) {
    font-size: 2.369rem;
  }
}

.h3,
h3 {
  font-weight: $heavy-weight;
  font-size: 1.333rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.777rem;
  }
}

.h4,
h4 {
  font-size: 1rem;
  font-weight: $heavy-weight;
  @include media-breakpoint-up(lg) {
    font-size: 1.333rem;
  }
}

.h5,
h5 {
  font-size: 1rem;
  font-weight: $normal-weight;
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}
// Line height

.reduce-line-height {
  line-height: 1 !important;
}

.sub-heading {
  font-size: 1.25rem;
  font-family: $subheading-font;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
}