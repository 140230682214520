/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
  @extend .transparent;
  border: 3px solid !important;
}

/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.gform_wrapper.gravity-theme .gfield input.large, .gform_wrapper.gravity-theme .gfield select.large, textarea {
  background: rgba(255,255,255,0)!important;
  border: 3px solid $brand-primary!important;
  outline: none!important;
  border-radius: 35px!important;
  padding: 8px 16px !important;
  color: $dark !important;
  font-family: $subheading-font;
  text-transform: uppercase;
}

.gform_wrapper {
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $dark !important;
    opacity: .5; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $dark !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $dark !important;
  }
}

#field_1_5 {
  text-align: center !important;
}

.gform_footer {
  justify-content: center;
}